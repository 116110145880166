<template>
  <v-container fluid>
    <v-card>
      <v-tabs>
        <v-tab dark>Password Resets & OTP Settings</v-tab>
        <v-tab-item>
          <v-row class="pa-3">
            <v-col cols="12">
              <v-col cols="6">
                <v-checkbox
                  outlined
                  dense
                  v-model="record.PswdChangeOnReset"
                  label="Enable Password Change after Reset"
                ></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  outlined
                  dense
                  v-model="record.HasOtpVerification"
                  label="Enable Otp Verification"
                ></v-checkbox>
              </v-col>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>

      <v-row>
        <v-col cols="12">
          <v-btn color="accent" @click="save" :loading="$store.state.loader">
            <v-icon left>mdi-content-save</v-icon>Save
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {},
  }),

  methods: {
    save() {
      const data = this.record;
      const url = "/settings/password_rest_change";
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          if (res) {
            this.$store.commit("loader", false);
            this.getData();
            this.$refs.snackbar.show("Updated Successfully", "green");
          }
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },
    getData() {
      const self = this;

      this.$store
        .dispatch("get", `/general_settings`)
        .then((res) => {
          self.record = res.ResponseData;
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>